body, header{
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Verdana, Arial, Helvetica ;
    color: #666666;
    background-size:30%; 
    background-clip:content-box; 
    background-position:top center; 
    background-repeat:no-repeat;
}
h1{
    font-size: 16pt;
    color: black;
}
#h1Certificado{
    color: #DB0000;
    margin-bottom: 5px;
    padding-bottom: 0;
}
.titulo {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #DB0000;
    font-weight: bold;
}
.borda {
    border: 1px solid #CCCCCC;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #666666;
}
.spacing {
    line-height: 16px;
}
.tituloBold {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: #DB0000;
    font-weight: bold;
}
.bordaBotom {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #666666;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: #DB0000;
}
.loading{
    line-height: 100%;
    text-align: center;
}
.error{
    padding: 30px 0 0 0;
    text-align: center;
}
.textCenter{
    text-align: center;
}
table.headerTable{
    border: 0 !important;
    border-collapse: separate !important;
    border-spacing: 5px !important;
    font-size: 12pt !important;
}
.rf-dt-hdr-c{
    padding-left:15px;
    font-size: 11pt !important;
    color: #666666;
    background-image: none !important;
    background-color: #E4E4E4 !important;
}
.rf-dt-c{
    font-size: 11pt !important;
    color: #666666 !important;
}
.headerTable th{
    text-align: center;
    height: 40px;
    background-color: #E4E4E4 !important;
    padding-left:15px;
    border: 0;
}
.headerTable td, .headerTable th{
    padding: 5px;
    padding-left:15px;
    height: 40px;
    border: 0;
}      
.tituloValorBold {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 15px;
    color: #DB0000;
    font-weight: bold;
}
.even-row{
    background-color: #F1f1f1;
}
.odd-row{
    background-color: #F9f9f9;        
}